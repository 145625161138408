<template>
  <div>
    <div v-if="tableData">
      <el-table
          :data="tableData"
          :show-header="false"
          style="width: 100%"
      >
        <el-table-column
            prop="text"
            label="项目"
            width="110"
        >
        </el-table-column>
        <el-table-column
            prop="value"
            label="内容"
        >
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import {getBulk9Details} from '@/api/eChnenergyTask'

export default {
  name: 'Bulk9',
  data() {
    return {
      tableData: undefined
    }
  },
  mounted() {
    document.title = '招标信息池-厦门国贸'
    getBulk9Details({id: this.$route.params.id}).then(res => {
      console.log(res)
      this.tableData = [
        {text: '询价单号', value: res[0].bidXid},
        {text: '询价名称', value: res[0].bidName},
        {text: '经营单位', value: res[0].businessUnit},
        {text: '报价截止时间', value: res[0].bidEndDate},
        {text: '运输模式', value: res[0].transportModeName},
        {text: '货物类型', value: res[0].goodsType},
        {text: '发货时间/受载期', value: res[0].deliveryTime},
        {text: '数量', value: res[0].nums},
        {text: '重量', value: res[0].weight},
        {text: '委托内容或补充要求', value: res[0].descriptionOfRule},
        {text: '发货地名称', value: res[0].sourceLocationName},
        {text: '发货地详细地址', value: res[0].sourceLocationAddress},
        {text: '收货地名称', value: res[0].destLocationName},
        {text: '收货地详细地址', value: res[0].destLocationAddress},
        {text: '线路备注', value: res[0].laneRemark},
        {text: '运费计费方式', value: res[0].billingMethod},
        {text: '计费单位', value: res[0].chargeUnit},
        {text: '含税金额', value: res[0].lowAmount},
        {text: '币种', value: res[0].currency},
        {text: '税率', value: res[0].taxRate},
        {text: '其他', value: res[0].others},
        {text: '状态', value: res[0].status},
      ]
    })
  },
  methods: {}
}
</script>

<style scoped>

</style>
